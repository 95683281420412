<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          v-if="$can('read', 'orderFilterAgent')"
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Agent</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            class="w-100"
            label="fullname"
            :reduce="val => val.id"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Van</label>
          <flat-pickr
            v-model="localDateFormFilter"
            class="form-control"
            name="dateFormFilter"
            :config="fromDateConfig"
            placeholder=""
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Tot</label>
          <flat-pickr
            v-model="localDateTillFilter"
            class="form-control"
            name="dateTillFilter"
            :config="toDateConfig"
            placeholder=""
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Verstuurd</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="ecedoCreatedFilter"
            :options="yesNoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:ecedoCreatedFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Actie vereist</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="needsAttentionFilter"
            :options="yesNoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:needsAttentionFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    flatPickr,
    vSelect,
  },
  props: {
    statusFilter: {
      type: [Array, null],
      default: null,
    },
    dateFormFilter: {
      type: [String, null],
      default: null,
    },
    dateTillFilter: {
      type: [String, null],
      default: null,
    },
    userFilter: {
      type: [Number, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    needsAttentionFilter: {
      type: [Number, null],
      default: null,
    },
    ecedoCreatedFilter: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      fromDateConfig: {
        maxDate: new Date(),
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      toDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      localDateFormFilter: null,
      localDateTillFilter: null,
      yesNoOptions: [
        { label: 'Ja', value: 1 },
        { label: 'Nee', value: 0 },
      ],
    }
  },
  watch: {
    dateFormFilter: {
      deep: true,
      handler(data) {
        this.localDateFormFilter = data
      },
    },
    dateTillFilter: {
      deep: true,
      handler(data) {
        this.localDateTillFilter = data
      },
    },
    localDateFormFilter: {
      deep: true,
      handler(data) {
        this.$emit('set-date-form', data)
      },
    },
    localDateTillFilter: {
      deep: true,
      handler(data) {
        this.$emit('set-date-till', data)
      },
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
