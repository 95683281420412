<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <section id="offer">
      <b-row>
        <b-col cols="12">
          <order-list-filters
            :user-filter.sync="userFilter"
            :status-filter.sync="statusFilter"
            :ecedo-created-filter.sync="ecedoCreatedFilter"
            :needs-attention-filter.sync="needsAttentionFilter"
            :user-options="userFilterOptions"
            :status-options="statusFilterOptions"
            :date-form-filter="dateFormFilter"
            :date-till-filter="dateTillFilter"
            @set-date-form="setDateFilter($event,'from')"
            @set-date-till="setDateFilter($event,'till')"
          />
          <b-card
            no-body
          >
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>Records</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-end"
                  cols="12"
                  md="6"
                >
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Zoeken ..."
                  />
                </b-col>
              </b-row>
            </div>
            <b-table
              ref="refOfferListTable"
              :items="fetchOrders"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              :sort-by-status.sync="sortByStatus"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  @change="row.toggleDetails"
                >
                  <!--                  {{ row.detailsShowing ? 'Verbergen' : 'Tonen' }}-->
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-table
                      :fields="tableEansColumns"
                      :items="row.item.connections"
                    >
                      <template #cell(ean)="data">
                        <small>{{ data.value }}</small>
                      </template>
                      <template #cell(bagPurpose)="data">
                        <small>{{ data.value }}</small>
                      </template>
                      <template #cell(postalCode)="data">
                        <span class="font-weight-bold d-block">
                          <small>{{ data.item.street }} {{ data.item.houseNumber }} {{ houseNumberAddition?'houseNumberAddition':'' }}</small>
                        </span>
                        <small class="text-muted">{{ data.item.postalCode }}, {{ data.item.city }}</small>
                      </template>
                      <template #cell(marketSegment)="data">
                        <span
                          v-if="data.value === 'ELECTRICITY' && data.item.meterType === 'SINGLE'"
                          class="font-weight-bold d-block"
                        >
                          <small style="width:100%">Enkel: {{ data.item.usageElectricitySingle }}</small>
                        </span>
                        <span
                          v-if="data.value === 'ELECTRICITY' && data.item.meterType !== 'SINGLE'"
                          class="font-weight-bold d-block"
                        >
                          <small style="width:100%">Normaal: {{ data.item.usageElectricityHigh }}</small>
                        </span>
                        <span
                          v-if="data.value === 'ELECTRICITY' && data.item.meterType !== 'SINGLE'"
                          class="font-weight-bold d-block"
                        >
                          <small style="width:100%">Dal: {{ data.item.usageElectricityLow }}</small>
                        </span>
                        <span
                          v-if="data.value === 'GAS'"
                          class="font-weight-bold d-block"
                        >
                          <small style="width:100%">{{ data.item.usageGas }} m3</small>
                        </span>
                      </template>
                      <template #cell(usageType)="data">
                        <small>{{ getUsageType(data.value) }}</small>
                      </template>
                      <template #cell(startDate)="data">
                        <small>{{ data.value ? formatDate(data.value) : '' }}</small>
                      </template>
                      <template #cell(status)="data">
                        <b-badge
                          :id="`invoice-row-${data.item.id}-status`"
                          :variant="getStatusType(data.value).variant"
                        >
                          {{ getStatusType(data.value).name }}
                        </b-badge>
                      </template>
                    </b-table>
                  </b-row>

                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Verberg details
                  </b-button>
                </b-card>
              </template>
              <template #cell(status)="data">
                <b-badge
                  v-if="data.item.needsAttention === 0 || data.item.needsAttention === 1 && data.value === 'CANCELLED'"
                  :id="`invoice-row-${data.item.id}-status`"
                  :variant="getStatusType(data.value).variant"
                >
                  {{ getStatusType(data.value).name }}
                </b-badge>
                <b-badge
                  v-else
                  :id="`invoice-row-${data.item.id}-status`"
                  :variant="getStatusType('NEEDS_ATTENTION').variant"
                >
                  {{ getStatusType('NEEDS_ATTENTION').name }}
                </b-badge>
              </template>

              <template #cell(accepted)="data">
                <i
                  v-if="(data.item.isSigned === 1 || data.item.signType === 'SIGNATURE') && data.item.ecedoCreated === 0"
                  class="fas fa-check ms-2 fs-6"
                  style="color: #50cd89"
                />
                <i
                  v-else-if="data.item.ecedoCreated === 1"
                  class="fas fa-upload ms-2 fs-6"
                  style="color: #50cd89"
                />
                <i
                  v-else-if="data.item.status === 'EXPIRED'"
                  class="fas fa-clock ms-2 fs-6"
                  style="color: #ff9f43"
                />
                <i
                  v-else
                  class="far fa-clock ms-2 fs-6"
                  style="color: #6e6b7b"
                />
              </template>

              <!-- Column: Id -->
              <template #cell(id)="data">
                <b-link
                  class="font-weight-bold"
                  :style="`color:${resolveColorVariant(data.item.status)}`"
                >
                  #{{ data.item.id }}
                </b-link>
              </template>
              <template #cell(customerRelation)="data">
                <b-media
                  vertical-align="center"
                >
                  <template #aside>
                    <a
                      href="/"
                      class="b-avatar router-link-active badge-secondary rounded-circle"
                      target="_self"
                      style="width: 32px; height: 32px;"
                    >
                      <span
                        v-if="data.value.type === 1 && data.value.gender === 'f'"
                        class="b-avatar-text"
                        style="font-size: 20px"
                      >
                        <span
                          style="font-size: 20px"
                          class="fas fa-female"
                        />
                      </span>
                      <span
                        v-if="data.value.type === 1 && data.value.gender === 'm'"
                        class="b-avatar-text"
                        style="font-size: 20px"
                      >
                        <span
                          style="font-size: 20px"
                          class="fas fa-male"
                        />
                      </span>
                      <span
                        v-if="data.value.type === 1 && (!data.value.gender || data.value.gender === '')"
                        class="b-avatar-text"
                        style="font-size: 20px"
                      >
                        <span
                          style="font-size: 20px"
                          class="fas fa-restroom"
                        />
                      </span>
                      <span
                        v-if="data.value.type === 2"
                        class="b-avatar-text"
                        style="font-size: 20px"
                      >
                        <span
                          style="font-size: 20px"
                          class="fas fa-building"
                        />
                      </span>
                    </a>
                  </template>
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.value.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.value.type === 1 ? getFullName(data.value) : data.value.companyName }}
                  </b-link>
                  <div class="text-muted">
                    {{ data.item.product.name }}
                  </div>
                </b-media>
              </template>

              <!-- Column: Issued Date -->
              <template #cell(signTimestamp)="data">
                <span class="text-nowrap">
                  <small>{{ data.value }}</small>
                </span>
              </template>

              <template #cell(user.fullname)="data">
                <span class="text-nowrap">
                  <small>{{ data.value }}</small>
                </span>
              </template>
              <template #cell(actions)="data">

                <div class="text-nowrap">
                  <b-link
                    :id="`order-view-action-row-${data.item.id}`"
                    :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                  >
                    <feather-icon
                      :id="`order-row-${data.item.id}-preview-icon`"
                      class="cursor-pointer mx-1 feather-hover"
                      icon="EyeIcon"
                      size="16"
                      :variant="resolveStatusVariant(data.item.status)"
                    />
                  </b-link>
                  <b-tooltip
                    :target="`order-view-action-row-${data.item.id}`"
                    placement="left"
                  >
                    <p class="mb-0">
                      Aanpassen
                    </p>
                  </b-tooltip>
                </div>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{ dataMeta.of }} records zichtbaar</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalOrders"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BLink,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { avatarText, kFormatter } from '@core/utils/filter'
import { formatDateTime, formatDate } from '@core/utils/utils'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useOrderList from '@/views/pages/order/useOrderList'
import orderStoreModule from '@/views/pages/order/orderStoreModule'
import OrderListFilters from '@/views/pages/order/order-list/OrderListFilters.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BLink,
    BPagination,
    BMedia,
    BTooltip,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    BButton,
    OrderListFilters,
    vSelect,
    BBadge,
  },
  data() {
    return {
      tabIndex: 0,
      userFilterOptions: [],
    }
  },
  beforeMount() {
    store.dispatch('app-order/fetchUsers', { isAll: true })
      .then(response => {
        this.userFilterOptions = response.data.items
      })
  },
  methods: {
    setDateFilter(data, type) {
      if (type === 'from') {
        this.dateFormFilter = data
      }
      if (type === 'till') {
        this.dateTillFilter = data
      }
    },
    kFormatter,
    getFullName(data) {
      let fullName = ''

      if (!data.firstName) {
        data.firstName = ''
      }

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    updateStatus(status) {
      if (String(this.$route.params.id) !== status) {
        this.$router.replace({ path: `/order/overview/${status}` })
      }
      this.sortByStatus = status
    },
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const actionRequired = ref(null)

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOfferListTable,
      sortByStatus,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      showOverlay,
      resolveTypeVariant,
      searchQuery,
      tableEansColumns,
      getUsageType,
      getStatusType,
      statusFilterOptions,
      userFilter,
      statusFilter,
      dateFormFilter,
      dateTillFilter,
      ecedoCreatedFilter,
      needsAttentionFilter,
      resolveStatusVariant,
      resolveColorVariant,
    } = useOrderList()

    return {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOfferListTable,
      sortByStatus,
      refetchData,
      statusOptions,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      showOverlay,
      resolveTypeVariant,
      actionRequired,
      formatDateTime,
      searchQuery,
      tableEansColumns,
      formatDate,
      getUsageType,
      getStatusType,
      statusFilterOptions,
      userFilter,
      statusFilter,
      dateFormFilter,
      dateTillFilter,
      ecedoCreatedFilter,
      needsAttentionFilter,
      resolveStatusVariant,
      resolveColorVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
